import { memo } from 'react';
import classNames from 'classnames';

import { PureLink } from '@jsmdg/yoshi';
import { isInternalLink } from '../../../helper/isInternalLink';
import styles from './LinkMenuItem.module.scss';

type LinkMenuItemProps = {
    readonly url: string;
    readonly label: string;
    readonly target?: string;
};

const UnmemoizedLinkMenuItem = ({
    label,
    target = '_self',
    url,
}: LinkMenuItemProps): JSX.Element => (
    <PureLink
        internal={isInternalLink(url)}
        className={classNames('d-flex align-items-center', styles.textButton)}
        href={url}
        target={target}
    >
        {label}
    </PureLink>
);

export const LinkMenuItem = memo(UnmemoizedLinkMenuItem);
